/*@import "../../node_modules/glightbox/dist/css/glightbox.min";*/
/* @import "../../node_modules/flickity/css/flickity"; */
@import "../../node_modules/mburger-css/dist/mburger";
@import "../../node_modules/mmenu-js/dist/mmenu";
/*@import "../../node_modules/mhead-js/dist/mhead";*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #nav-primary {
    a.link {
      @apply flex items-center h-full transition md:p-4 p-2 text-white
    }

    a.link:hover {
      @apply bg-white bg-opacity-20
    }

    a.active {
      box-shadow: 0 3px 0 0 theme('colors.primary.DEFAULT') inset;
    }

    li {
      @apply h-full items-center
    }
  }

  mark {
    @apply bg-primary bg-opacity-30
  }
  .prose ul>li::before {
    content: url('../assets/icon-check.svg');
    background-color: unset;
    @apply list-none w-5 h-5 left-0 top-1 inline-block opacity-75
  }
}


.gslide-video {
  max-width: 90vw !important;
}


.mm-listitem__text {
  text-overflow: initial;
  white-space: normal;
  overflow: auto;
  line-height: 3rem;
  @apply text-xl text-white;
}
.mm-menu {

--mm-color-background: theme('colors.dark.DEFAULT');

}

main {
  overflow: hidden;
}
